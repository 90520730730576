<template>
  <div id="minimal-bootstrap-carousel" data-ride="carousel" class="carousel slide carousel-fade shop-slider">
    <div role="listbox" class="carousel-inner">
      <div style="background: url(/img/day-rig.jpg) center right;" class="item active slide-1">
        <div class="carousel-caption">
          <div class="thm-container">
            <div class="box valign-bottom">
              <div class="content text-center">
                <h2 data-animation="animated fadeInLeft" class="shadow-2px"><span>From Top to Bottom</span>
                  <br>We've got you covered
                </h2>
                <p data-animation="animated fadeInDown" class="shadow-2px">
                  Drilling * Completion * Production * Transport * Storage * Safety * Environmental
                </p>
                <div class="buttons">
                  <a data-animation="animated fadeInLeft" href="/learn_more" class="thm-button hvr-bounce-to-right">Learn
                    more </a>
                  <a data-animation="animated fadeInRight" href="/contact_us"
                     class="thm-button inverse hvr-bounce-to-right2">Contact Us</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div style="background: url(/img/gas-pad.jpg) center right;" class="item slide-2">
        <div class="carousel-caption">
          <div class="thm-container">
            <div class="box valign-bottom">
              <div class="content text-center">
                <h2 data-animation="animated fadeInLeft" class="shadow-2px"><span>From Top to Bottom</span>
                  <br>We've got you covered
                </h2>
                <p data-animation="animated fadeInDown" class="shadow-2px">
                  Drilling * Completion * Production * Transport * Storage * Safety * Environmental
                </p>
                <div class="buttons">
                  <a data-animation="animated fadeInLeft" href="#learn_more" class="thm-button hvr-bounce-to-right">Learn
                    more </a>
                  <a data-animation="animated fadeInRight" href="#contact_us"
                     class="thm-button inverse hvr-bounce-to-right2">Contact Us</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div style="background: url(/img/winter-rig.jpg) center right;" class="item slide-3">
        <div class="carousel-caption">
          <div class="thm-container">
            <div class="box valign-bottom">
              <div class="content text-center pull-right">
                <h2 data-animation="animated fadeInUp" class="shadow-2px"><span>From Top to Bottom</span>
                  <br>We've got you covered
                </h2>
                <p data-animation="animated fadeInDown" class="shadow-2px">
                  Drilling * Completion * Production * Transport * Storage * Safety * Environmental
                </p>
                <div class="buttons">
                  <a data-animation="animated fadeInLeft" href="#learn_more" class="thm-button hvr-bounce-to-right">Learn
                    more </a>
                  <a data-animation="animated fadeInRight" href="#contact_us"
                     class="thm-button inverse hvr-bounce-to-right2">Contact Us</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <a href="#minimal-bootstrap-carousel" role="button" data-slide="prev" class="left carousel-control unicon-center">
      <unicon name="angle-left-b" height="32" width="32" fill="white"></unicon>
      <span class="sr-only">Previous</span>
    </a>
    <a href="#minimal-bootstrap-carousel" role="button" data-slide="next"
       class="right carousel-control unicon-center">
      <unicon name="angle-right-b" height="32" width="32" fill="white"></unicon>
      <span class="sr-only">
        Next
      </span>
    </a>
  </div>
</template>

<script>
export default {
  name: 'JumboTron'
}
</script>

<style lang="scss" scoped>
#minimal-bootstrap-carousel {
  z-index: 0;
  .unicon-center {
    padding-top: 4px;
  }
  .carousel-inner-two {
    height: 900px;
  }
  .slide-3 {
    .carousel-caption {
      h2.gray {
        color: #414141;
      }
      p.gray {
        color: #414141;
      }
      .thm-button {
        padding: 12px 33px;
        background: $orange;
        color: #FFFFFF;
        border: none;
        margin-top: 25px;
        font-family: 'Poppins', sans-serif;
        font-weight: 400;
        margin-right: 20px;
        -webkit-animation-delay: 1.5s !important;
        animation-delay: 1.5s !important;
      }
      .thm-button.inverse {
        padding: 12px 33px;
        background: $blue;
        color: #FFFFFF;
        border: none;
        margin-top: 25px;
        font-family: 'Poppins', sans-serif;
        font-weight: 400;
        margin-right: 20px;
      }
      .content {
        padding-top: 135px;
        padding-left: 490px;
      }
      h2 {
        text-align: left;
        margin: 0;
        -webkit-animation-delay: .5s;
        animation-delay: .5s;
      }
      p {
        font-size: 17px;
        -webkit-animation-delay: 1s;
        animation-delay: 1s;
        margin-bottom: 25px;
      }
      span.border {
        width: 330px;
      }
    }
  }
  .carousel-inner {
    height: 720px;
    .item {
      min-height: 640px;
      height: 100%;
      width: 100%;
      background-size: cover;
      background-position: center center;
      background-color: #1E1E1E;
    }
  }
  .carousel-caption {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    padding: 0;
    text-align: right;
    text-shadow: none;
    .thm-container {
      display: table;
      width: 100%;
      height: 100%;
      max-width: 1170px;
      margin-left: auto;
      margin-right: auto;
      text-align: center;
      .box {
        display: table-cell;
        text-align: left;
        .content {
          display: block;
        }
      }
      .box.valign-top {
        vertical-align: top;
      }
      .box.valign-bottom {
        vertical-align: bottom;
        .content {
          padding-bottom: 200px;
        }
      }
      .box.valign-middle {
        vertical-align: middle;
      }
    }
    h2 {
      font-family: 'Poppins', sans-serif;
      color: #fff;
      margin: 0;
      font-size: 55px;
      line-height: 55px;
      font-weight: 700;
      -webkit-animation-delay: .5s;
      animation-delay: .5s;
      span {
        color: $orange;
      }
    }
    p {
      font-style: italic;
      font-family: 'Poppins', sans-serif;
      text-align: left;
      font-size: 17px;
      line-height: 28px;
      margin-top: 20px;
      margin-bottom: 40px;
      -webkit-animation-delay: 1s;
      animation-delay: 1s;
    }
    a.thm-button {
      -webkit-animation-delay: 1.5s;
      animation-delay: 1.5s;
      &:last-child {
        -webkit-animation-delay: 2s;
        animation-delay: 2s;
      }
    }
    span.dib {
      display: inline-block;
      vertical-align: middle;
    }
    span.border {
      display: inline-block;
      width: 270px;
      height: 1px;
      background: #FFFFFF;
      vertical-align: inherit;
      position: relative;
      top: 3px;
    }
    .buttons {
      text-align: left;
      margin-top: 0;
    }
  }
  .carousel-control {
    width: 40px;
    height: 40px;
    font-size: 15px;
    line-height: 40px;
    color: #fff;
    background: rgba(0, 0, 0, 0.5);
    visibility: visible !important;
    opacity: .65;
    margin-top: -20px;
    -webkit-transition: all 0.5s ease;
    transition: all 0.5s ease;
    top: 50%;
    border-radius: 0;
    z-index: 99;
    &:hover {
      opacity: 1;
      background: #000;
    }
  }
  .carousel-control.left {
    left: 1%;
  }
  .carousel-control.right {
    right: 1%;
  }
  .slide-1 {
    .carousel-caption {
      h2 {
        margin: 0;
        text-align: left;
      }
      .thm-button {
        padding: 12px 33px;
        background: $orange;
        color: #FFFFFF;
        border: none;
        margin-top: 25px;
        font-family: 'Poppins', sans-serif;
        font-weight: 400;
        margin-right: 20px;
      }
      .thm-button.inverse {
        padding: 12px 33px;
        background: $blue;
        color: #FFFFFF;
        border: none;
        margin-top: 25px;
        font-family: 'Poppins', sans-serif;
        font-weight: 400;
        margin-right: 20px;
      }
    }
  }
  .slide-2 {
    .carousel-caption {
      .content {
        padding-top: 160px;
      }
      h3 {
        color: #C9D705;
        margin: 0;
        font-size: 40px;
        line-height: 55px;
        font-weight: bold;
      }
      .thm-button {
        padding: 12px 33px;
        background: $orange;
        color: #FFFFFF;
        border: none;
        margin-top: 25px;
        font-family: 'Poppins', sans-serif;
        font-weight: 400;
        margin-right: 20px;
        -webkit-animation-delay: 2s !important;
        animation-delay: 2s !important;
      }
      .thm-button.inverse {
        padding: 12px 33px;
        background: $blue;
        color: #FFFFFF;
        border: none;
        margin-top: 25px;
        font-family: 'Poppins', sans-serif;
        font-weight: 400;
        margin-right: 20px;
      }
      h2 {
        margin: 0;
        text-align: left;
      }
      p {
        margin: 19px 0 0;
      }
      span.dib {
        display: inline-block;
        vertical-align: middle;
      }
      span.border {
        display: inline-block;
        width: 270px;
        height: 1px;
        background: #FFFFFF;
        vertical-align: inherit;
        position: relative;
        top: 3px;
      }
    }
  }
}
.carousel-fade {
  .carousel-inner {
    .item {
      -webkit-transition-property: opacity;
      transition-property: opacity;
      opacity: 0;
    }
    .active.left {
      opacity: 0;
      left: 0;
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
    }
    .active.right {
      opacity: 0;
      left: 0;
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
    }
    .active {
      opacity: 1;
    }
    .next.left {
      opacity: 1;
    }
    .prev.right {
      opacity: 1;
    }
    .next {
      left: 0;
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
    }
    .prev {
      left: 0;
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
    }
  }
  .carousel-control {
    z-index: 2;
  }
}
.shadow-2px {
  text-shadow: 2px 2px #2a2a2a;
}
.item {
  &:hover {
    .overlay {
      width: 100%;
    }
  }
}



</style>