export default [
    {
        url: 'https://www.belgasfm.com/',
        abbr: 'bgfm',
        name: 'BelGas Flame Management',
        desc: 'Flame Arrestors, Tank Venting Accessories, Burners, and Specialty Fabrication Services.'
    },
    {
        url: 'https://cncflowcontrol.com',
        abbr: 'ccv',
        name: 'C & C Valve',
        desc: 'Gate, Check, Ball, Butterfly, & Needle Valves, Fittings, Unions, and Gaskets'
    },
    {
        url: 'https://drillmax.com',
        abbr: 'dmi',
        name: 'Drillmax, Inc.',
        desc: 'API Gate Valves, Drill Pipe Float Valves, and Replacement Parts'
    },
    {
        url: 'https://ezflowcontrol.com',
        abbr: 'ezf',
        name: 'EZ-Flow',
        desc: 'Chokes, Valves, Manifolds, Trim Sets, & Repair Parts'
    },
    {
        url: 'https://flomore.com',
        abbr: 'flo',
        name: 'Flo-more',
        desc: 'Electric & Solar Chemical Pumps, Check Valves, Gauge Glass, and Measurement Accessories'
    },
    {
        url: 'https://hspc.co',
        abbr: 'hsp',
        name: 'Houston Specialty Products Co',
        desc: 'Rubber Parts: Swab Cups, Stripper Rubbers, Pipe Wipers, Oil Savers, BOP soft goods, and Sucker Rod Guides.'
    },
    {
        url: 'https://foo.com/bar',
        abbr: 'jcc',
        name: 'Jay Courtney Co',
        desc: 'Vent Valves & Thief Hatches'
    },
    {
        note: "This is a special case, Ratigan does not have a website. So we'll allocate some space to them in ours.",
        url: 'https://repsales.net/jpratigan',
        abbr: 'jpr',
        name: 'JP Ratigan',
        desc: 'Blowout Preventers, Stuffing Boxes, Elevators, Wrenches, and Accessories.'
    },
    {
        url: 'https://kengro.com',
        abbr: 'kgo',
        name: 'Kengro',
        desc: 'Biosorb, Delta-Dri, and Kengro-X: Organic, Natural Bio-Remediation Alternative'
    },
    {
        url: 'https://taylorvalve.com/product/mallard/control-valves/',
        abbr: 'mcp',
        name: 'Mallard Control Products',
        desc: 'Control Valves, Regulators, and Accessories'
    },
    {
        url: 'https://owvalvellc.com',
        abbr: 'owv',
        name: 'O.W. Valve',
        desc: 'Domestic Floating & Trunnion Ball Valves, Swing Check Valves.'
    },
    {
        url: 'https://performancepulsation.com',
        abbr: 'ppc',
        name: 'Performance Pulsation',
        desc: 'Discharge Dampeners, Status Flow Products, Suction Stabilizers, System Dampeners '
    },
    {
        url: 'https://polymatecorp.com',
        abbr: 'pmc',
        name: 'Polymate Corporation',
        desc: '316 Stainless and Aluminum Bronze Couplings, Bushings, Swages, KC Nipples, Hammer Unions, and Bull Plugs.'
    },
    {
        url: 'https://prec-int.com',
        abbr: 'pip',
        name: 'Precision International',
        desc: 'API 11X Downhole Pump Parts'
    },
    {
        url: 'https://prlmfg.com/',
        abbr: 'prl',
        name: 'PRL Manufacturing',
        desc: 'Polished Rods, Liners, and Sinker Bars'
    },
    {
        url: 'https://socousa.com',
        abbr: 'scp',
        name: 'South Coast Products',
        desc: 'Drilling, Tool Joint, Drill Collar, and API Modified Thread Compounds, Lubricants, Thread Sealants, Greases, Pipe Dopes, Rig Wash, Pipe Yard Coatings, Injectable Packaging'
    },
    {
        url: 'https://taylorvalve.com',
        abbr: 'tvc',
        name: 'Taylor Valve Corp',
        desc: 'Safety Relief Valves, Choke Valves, Liquid Level Measurement & Control'
    },
    {
        url: 'https://tnemec.com',
        abbr: 'tci',
        name: 'Tnemec Company, Inc',
        desc: 'Paints, Primers, and Coatings - OH, WV, PA Only'
    },
    {
        url: 'http://www.youngenergyproducts.com/',
        abbr: 'yep',
        name: 'Young Energy Products',
        desc: 'Valves, Clamps, Couplings & Repair Products'
    },

    {
        url: 'https://wellheadsystems.com',
        abbr: 'wsi',
        name: 'Wellhead Systems, Inc.',
        desc: 'Casing & Tubing Heads, Stuffing Boxes, Polish Rod Clamps, Blowout Preventers, Flow Tees'
    }
];
