import VueRouter from 'vue-router'
import SiteHome from './components/SiteHome.vue'
import OfficeTeam from './components/OfficeTeam.vue'
import NotFound from './components/errors/NotFound.vue'
import AboutCompany from './components/AboutCompany.vue'
import ManufacturerList from './components/section-manufacturers/ManufacturerList.vue'
import ManufacturerProfile from './components/section-manufacturers/ManufacturerProfile.vue'
import DeliveryService from './components/section-about/DeliveryService.vue'
import ExperienceMatters from './components/section-about/ExperienceMatters.vue'
import PersonalTouch from './components/section-about/PersonalTouch.vue'
import FootballPool from './components/section-office-pools/FootballPool.vue'
import BasketballPool from './components/section-office-pools/BasketballPool.vue'

import manufacturers from './assets/js/manufacturers.js'

let routes = [
    { path: '/', component: SiteHome },
    { path: '/about/team', component: OfficeTeam },
    { path: '/about/company', component: AboutCompany },
    { path: '/manufacturers', component: ManufacturerList },
    { path: '/pools/football', component: FootballPool },
    { path: '/pools/basketball', component: BasketballPool },
    { path: '/delivery', component: DeliveryService },
    { path: '/experience', component: ExperienceMatters },
    { path: '/personal', component: PersonalTouch }
];

manufacturers.forEach(function(manufacturer){
    routes.push({
        path: '/manufacturers/'+manufacturer.abbr,
        component: ManufacturerProfile,
        props: manufacturer
    });
});

routes.push({ path: '*', component: NotFound });

export default new VueRouter({
    mode: 'history',
    routes,
    scrollBehavior (to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition
        } else {
            return { x: 0, y: 0 }
        }
    }
})
