/**
 * This will some day be a much more sophisticated set of options, to support
 * responsive needs, etc.
 */
export default {
    autoplay: false,
    autoplayTimeout: 2250,
    autoplayHoverPause: true,
    easing: 'linear',
    perPage: 1,
    perPageCustom: [[768, 2], [1024, 3], [1280, 4]],
    pagination: false,
    loop: true,
    speed: 1200
}