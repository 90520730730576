<template>
  <div class="manufacturer-list-card">
    <router-link :to="rsi_link">
        <div class="row">
            <img :src="img_link" :title="title" :alt="title" class="manufacturer-list-image" />
        </div>
        <div class="row">
          <div class="col-xs-12">
            {{desc}}
          </div>
        </div>
    </router-link>
  </div>
</template>

<script>
export default {
    name: 'ManufacturerListCard',
    props: {
        'abbr' : String,
        'title' : String,
        'desc' : String,
    },
    data: function() {
        return {
            rsi_link : "/manufacturers/"+this.abbr,
            img_link : "/img/partners/"+this.abbr+"/"+this.abbr+"-small.png",
        }
    },
}
</script>

<style lang="scss" scoped>
  .manufacturer-list-image {
    max-height: 110px;
    max-width: 220px;
  }
  .manufacturer-list-card {
    padding: 1em;
    &:hover {
      outline: #e9e9e9 solid 1px;
    }
  }
  a {
    color: #5a5a5a;
    &:hover {
      color: $light-blue;
    }
  }
</style>