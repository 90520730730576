<template>
  <div>
    <header class="main-header">
      <div class="container">
        <div class="row">
          <div class="col-md-3">
            <div class="logo">
              <a href="/"><img src="/img/rsi-logo-header.png" alt="REP Sales, Inc. - Logo"/></a>
            </div>
          </div>
          <div class="col-md-9">
            <div class="general-info">
              <ul>
                <li>
                  <div class="icon">
                    <unicon name="outgoing-call"></unicon>
                  </div>
                  <div class="text">
                    <p>
                      Olney, IL
                      <br>
                      <span><a href="tel:800-274-2003" style="font-weight: bold">800-274-2003</a></span>
                    </p>
                  </div>
                </li>
                <li>
                  <div class="icon">
                    <unicon name="outgoing-call"></unicon>
                  </div>
                  <div class="text">
                    <p>
                      Midvale, OH
                      <br>
                      <span><a href="tel:800-501-7979" style="font-weight: bold">800-501-7979</a></span>
                    </p>
                  </div>
                </li>
                <li>
                  <div class="icon">
                    <unicon name="envelope-alt"></unicon>
                  </div>
                  <div class="text">
                    <p>
                      Email Us
                      <br>
                      <span><strong><a href="mailto:info@repsales.net">info@repsales.net</a></strong></span>
                    </p>
                  </div>
                </li>
              </ul>
            </div>
            <div class="button hidden-xs hidden-sm hidden-md">
              <a class="main-header hvr-bounce-to-right" href="/#contact_us">REQUEST A QUOTE</a>
            </div>
          </div>
        </div>
      </div>
    </header>
    <NavBar/>
  </div>
</template>

<script>

import NavBar from './NavBar'

export default {
  name: 'SiteHeader',
  components: {
    NavBar,
  },
  data() {
    return {}
  }
}

</script>

<style lang="scss">
  .icon {
    padding-top: .8em;
  }
  #main-header {
    position: absolute;
    background: rgba(0, 0, 0, .5);
    z-index: 100;
    width: 100%;
  }
  .main-header {
    padding: 30px 0 19px 0;
    ul {
      list-style-type: none;
      margin-left: 50px;
      margin-bottom: 0;
      margin-top: 3px;
      li {
        display: inline-block;
        list-style-type: none;
        margin-left: 20px;
      }
      p {
        color: #6c6c6c;
        font-size: 15px;
        font-weight: 300;
      }
      span {
        color: #232932;
        font-size: 14px;
        font-weight: bold;
      }
    }
    .icon {
      float: left;
      display: block;
      i {
        font-size: 1.7em;
        line-height: 30px;
        color: #c0c0c0;
      }
    }
    .general-info {
      float: left;
    }
    .text {
      float: left;
      display: block;
      margin-left: 10px;
    }
    .button-wrapper {
      display: inline-block;
      vertical-align: middle;
    }
    .button {
      float: right;
      a {
        display: block;
        background: $orange;
        color: #ffffff;
        text-transform: uppercase;
        font-size: 14px;
        font-weight: bold;
        padding: 14px 25px;
      }
    }
  }


</style>