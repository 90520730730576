import Unicon from 'vue-unicons'
import {
    uniExternalLinkAlt,
    uniOutgoingCall,
    uniEnvelopeAlt,
    uniCloudDownload,
    uniLocationPoint,
    uniClock,
    uniFootballAmerican,
    uniLinkedinAlt,
    uniSkypeAlt,
    uniFacebookF,
    uniLink,
    uniCreditCard,
    uniTruck,
    uniQrcodeScan,
    uniBox,
    uniFavoriteMonochrome,
    uniAngleLeftB,
    uniAngleRightB,
    uniUserCircle,

} from 'vue-unicons/src/icons'

Unicon.add([
    uniExternalLinkAlt,
    uniOutgoingCall,
    uniEnvelopeAlt,
    uniCloudDownload,
    uniLocationPoint,
    uniClock,
    uniFootballAmerican,
    uniLinkedinAlt,
    uniSkypeAlt,
    uniFacebookF,
    uniLink,
    uniCreditCard,
    uniTruck,
    uniQrcodeScan,
    uniBox,
    uniFavoriteMonochrome,
    uniAngleLeftB,
    uniAngleRightB,
    uniUserCircle,

])

// https://antonreshetov.github.io/vue-unicons/  <-- find more icons here.

export default Unicon;