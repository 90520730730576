<template>
    <section class="offer-wrapper" id="learn_more">
        <div class="container">
            <div class="col-md-8 col-md-offset-2">
            <p class="text-center">
                <img src="/img/territory.png" loading="lazy" alt="Territory: IL, IN, KY, MI, NY, OH, PA, WV, VA. Facilities: IL, MI, OH" style="max-height: 14em">
            </p>
            <p>
                REP Sales has been alive and kicking since 1984. Our headquarters are in Olney, Illinois, but our reach is all the way to New York in the northeast, and Virginia in the Southeast.
            </p>
            <p>We officially cover: IL, IN, KY, MI, OH, WV, PA, VA, NY from one of our two warehouses (Olney, IL and Midvale, OH) and one salesman's garage (MI).</p>
            <p>We represent manufacturers as commissioned reps, bringing your products to the back rooms and shelves of Oil & Gas Supply Stores throughout our territory.</p>
            <p>Delivering deep experience and the ability to survive long down-turns (we've done it four times now), we value relationships and can help leverage those relationships to get your
            products a fair chance in a competitive, and very closed marketplace.</p>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    name: 'AboutCompany'
}
</script>

<style lang="scss" scoped>
</style>