<template>
    <section class="numbers">
          <div class="container">
              <div class="row statistic">
                  <div class="col-md-4">
                      <h2>Our commitment to you:
                          <br>
                          <span>Total Coverage</span></h2>
                  </div>
                  <div class="col-md-12">
                      <div class="box">
                          <div class="number-box">
                              <span class="counter">18060</span>
                          </div>
                          <div class="text-box">
                              <p>Inventory Items</p>
                          </div>
                      </div>
                      <div class="box">
                          <div class="number-box">
                              <span class="counter">86</span>
                          </div>
                          <div class="text-box">
                              <p>Product Categories</p>
                          </div>
                      </div>
                      <div class="box">
                          <div class="number-box">
                              <span class="counter">22</span>
                          </div>
                          <div class="text-box">
                              <p>Manufacturers Represented</p>
                          </div>
                      </div>
                      <div class="box">
                          <div class="number-box">
                              <span class="counter">3</span>
                          </div>
                          <div class="text-box">
                              <p>Sales & Delivery Centers</p>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </section>
</template>

<script>
export default {
    name: 'StatsCounter'
}
</script>

<style lang="scss" scoped>
.numbers {
  padding: 50px 0;
  background: url(../../public/img/numbers.jpg) center top;
  h2 {
    text-align: left;
    font-size: 28px;
    color: $orange;
    line-height: 40px;
    font-family: 'Poppins', sans-serif;
    font-weight: 800;
    margin: 0 0 60px;
    span {
      color: #FFFFFF;
    }
  }
  .number-box {
    text-align: left;
    margin: 0;
    font-size: 46px;
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    line-height: 40px;
    text-transform: capitalize;
    color: $orange;
  }
  .text-box {
    p {
      text-align: left;
      margin: 0;
      font-size: 14px;
      font-family: 'Poppins', sans-serif;
      font-weight: 300;
      line-height: 20px;
      text-transform: capitalize;
      color: #FFFFFF;
    }
  }
  .box {
    float: left;
    margin-left: 2px;
    margin-right: 40px;
    border: 1px solid $orange;
    padding: 15px 20px;
    width: 233px;
    height: 112px;
  }
  .statistic {
    margin-top: 20px;
  }
}
</style>