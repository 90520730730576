<template>
  <section class="offer-wrapper profile-container" id="learn_more">
    <div class="container">
      <div class="row">
        <div class="col-md-8">
          <h3>{{ manufacturer.name }}</h3>
          <p class="content-body" v-html="manufacturer.profile">
          </p>
        </div>
        <div class="col-md-4 text-center">
          <a :href="manufacturer.url" target="_blank">
            <img :src="manufacturer.logo" class="image-logo" :alt="manufacturer.name" style="max-width: 275px"/>
          </a>
          <br/>
          <ButtonPrimary :to="manufacturer.url" target="_blank"><span style="font-size: 1.4em;padding-right: 1.2em">SEE MORE</span><unicon name="external-link-alt" height="16" fill="white"></unicon>
          </ButtonPrimary>
        </div>
      </div>
      <div class="row text-center" v-if="manufacturer.products.length > 0">
        <h4>Featured Products</h4>
        <hr>
        <div class="row">
          <div class="col-xs-12">
            <Carousel v-bind="carouselProps">
              <Slide :key="product.id" v-for="product in manufacturer.products" class="text-center">
                <a :href="product.url" :title="product.alt"  target="_blank">
                  <img :src="product.image" :alt="product.alt" style="max-width:150px; max-height: 150px">
                </a>
                <br/><br/>
                {{ product.alt }}
              </Slide>
            </Carousel>
          </div>
        </div>
        <div class="row text-center outro content-body">
          For more information - and more products - call <a href="tel:8002742003">800-274-2003</a> or visit
          <a :href="manufacturer.url">
            {{manufacturer.name}}'s website
          </a>
        </div>
      </div>
    </div>
  </section>
</template>

<script>

import ButtonPrimary from '../ux/ButtonPrimary.vue'
import carouselProps from '../../assets/js/carouselProps.js'
import {Carousel, Slide} from 'vue-carousel';

export default {
  name: "ManufacturerProfile",
  components: {
    ButtonPrimary,
    Carousel,
    Slide,
  },
  props: {
    abbr: String,
  },
  mounted() {
    this.setManufacturer();
  },
  methods: {
    async setManufacturer() {
      const response = await this.$http.get('/manufacturers/' + this.abbr + '.json');
      this.manufacturer = response.data;
    }
  },
  data() {
    return {
      manufacturer: {
        name: null,
        url: '',
        profile: null,
        images: {
          logo: null,
          carousel: [],
        },
        products: [],
      },
      carouselProps: carouselProps,
    };
  },
};
</script>

<style lang="scss" scoped>
.profile-container {
  min-height: 45em;
}

.image-header {
  max-width: 100%;
  height: 75px;
}

.content-body {
  padding-top: 1em;
  line-height: 1.8em;
  font-weight: 100;
}
.outro {
  padding-top: 3.2em;
  font-size: 1.6em;
  a {
    font-size: 1em;
    color: #FA3600;
    text-decoration: underline;
    font-weight: 600;
  }
  a:hover {
    color: #1a3b66;
  }
}
</style>