<template>
  <div>
    <footer class="main-footer">
      <div class="container">
        <div class="row">
          <div class="col-md-4">
            <figure>
              <img class="img-responsive" src="/img/rsi-logo-header.png" alt="awsome image"/>
            </figure>
            <p>
              We've been bringing a small business mindset and personal service to the
              Northeastern Oil & Gas Industry since 1982. We represent manufacturers of
              PVF and related products. If one of our sources doesn't make it, we can still
              help find almost anything. Give us a call and let us wow you.
            </p>
          </div>
          <div class="col-md-4 sec-tab">
            <h2>Market Segments</h2>
            <div class="footer-news text-center">
              <div class="row text-center">
                <p><strong>Drilling</strong></p>
              </div>
              <div class="row text-center">
                <p><strong>Production</strong></p>
              </div>
              <div class="row text-center">
                <p><strong>Transportation</strong></p>
              </div>
              <div class="row text-center">
                <p><strong>Storage</strong></p>
              </div>
              <div class="row text-center">
                <p><strong>Safety</strong></p>
              </div>
              <div class="row text-center">
                <p><strong>Environmental</strong></p>
              </div>
              <div class="row text-center">
                <p><strong>Flame Management</strong></p>
              </div>
            </div>
          </div>
          <div class="col-md-4">
            <h2>Get in Touch</h2>
            <ul class="contact-info">
              <li>
                <div class="col-xs-2">
                  <unicon name="location-point" height="28" width="28" :fill="orange"></unicon>
                </div>
                <div class="col-xs-10">
                  <div class="address">1124 S. Whittle Ave<br> Olney, IL 62450</div>
                </div>
              </li>
              <li>
                <div class="col-xs-2">
                  <unicon name="outgoing-call" height="28" width="28" :fill="orange"></unicon>
                </div>
                <div class="col-xs-10">
                  <div class="address">
                    <a href="https://goo.gl/maps/CgnS3zcWvtv92HHx9">800-274-2003 (Phone)<br> 800-395-FTGS (Fax)
                    </a>
                  </div>
                </div>
              </li>
              <li>
                <div class="col-xs-2">
                  <unicon name="clock" height="28" width="28" :fill="orange"></unicon>
                </div>
                <div class="col-xs-10">
                  <div class="address">M to F - 7:00 to 17:00<br> After-Hours Available</div>
                </div>
              </li>
              <li>
                <div class="col-xs-2">
                  <unicon name="envelope-alt" height="28" width="28" :fill="orange"></unicon>
                </div>
                <div class="col-xs-10">
                  <div class="address"><a href="mailto:info@repsales.net">info@repsales.net</a></div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </footer>
    <CopyrightBar/>
  </div>
</template>

<script>
import CopyrightBar from './CopyrightBar'

export default {
  name: 'SiteFooter',
  components: {
    CopyrightBar,
  },
  data() {
    return {
      orange: window.orange,
      blue: window.blue
    }
  },
  methods: {
    foo: () => {
      return 123;
    }
  }
}
</script>
<style lang="scss" scoped>
.main-footer {
  padding: 80px 0;
  background: $blue;

  .sec-tab {
    padding-left: 50px;
  }

  h2 {
    text-align: left;
    margin-bottom: 30px;
    margin-top: 0;
    font-size: 21px;
    color: #e7e7e8;
    font-family: 'Poppins', sans-serif;
    font-weight: 800;
  }

  p {
    text-align: left;
    font-size: 14px;
    color: #F4F4F4;
    font-family: 'Poppins', sans-serif;
    font-weight: 300;
    margin: 19px 0 0;
    line-height: 27px;
  }

  .social-icons {
    margin-top: 20px;

    ul {
      li {
        display: inline-block;
        margin-right: 10px;

        a {
          display: block;
          height: 34px;
          width: 34px;
          background: #b9b9b9;
          text-align: center;
          line-height: 34px;
          color: #F4F4F4;
          transition: all 0.5s ease;
          border-radius: 3px;

          &:hover {
            background: $orange;
          }
        }
      }
    }
  }

  ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    font-family: 'Poppins', sans-serif;
    font-weight: 300;
  }

  .solutions {
    margin-top: 60px;

    li {
      margin-top: 20px;

      a {
        &:hover {
          color: $orange;
        }

        color: #F4F4F4;
        font-weight: 300;
        transition: all .3s ease;
      }
    }
  }

  .footer-news {
    p {
      margin-top: 10px;
      line-height: 22px;

      span {
        color: #9e9e9e;
        line-height: 30px;
      }
    }
  }

  .contact-info {
    li {
      margin-top: 30px;
      padding-left: 20px;
      color: #F4F4F4;
      line-height: 20px;
      font-weight: 300;
      margin-bottom: 15px;
      clear: both;
      overflow: hidden;

      i {
        color: $orange;
        float: left;
        font-size: 24px;
        width: 35px;
      }
    }

    .address {
      float: left;

      a {
        color: #F4F4F4;
        transition: all .3s ease;

        &:hover {
          color: $orange;
        }
      }
    }
  }
}

</style>
