<template>
      <section class="footer-bottom">
          <div class="container">
              <div class="row">
                  <div class="pull-left">
                      <p class="hot">&copy; REP Sales, Inc 2020. All rights reserved</p>
                  </div>
                  <div class="pull-right">
                      <p>Management & Hosting by <a href="https://zbmowrey.com">ZBMowrey.com</a></p>
                  </div>
              </div>
          </div>
      </section>
</template>

<script>
export default {
    name: 'CopyrightBar'
}
</script>

<style lang="scss" scoped>
.footer-bottom {
  padding: 30px 0;
  background: #f2f2f2;
  p {
    color: #a3a3a3;
    margin: 0;
    font-size: 13px;
    font-weight: 300;
  }
}
</style>