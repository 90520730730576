<template>
    <section class="our-team">
          <div class="container">
              <div class="row">
                  <div class="col-md-12">
                      <div class="team-text">
                          <div class="title">
                              <h2 id="sales_team">Our Sales Team</h2>
                          </div>
                      </div>
                  </div>
                  <div class="col-md-12">
                      <div class="members">
                          <div class="member">
                              <figure>
                                  <img src="img/jmowrey.jpg" alt="Sales Manager - All Regions" />
                                  <div class="overlay">
                                      <div class="inner-box">
                                          <ul class="social">
                                              <li><a href="https://www.facebook.com/jim.mowrey.92"><unicon height="20" width="20" fill="white" class="sales-social" name="facebook-f"></unicon></a></li>
                                              <li><a href="https://www.linkedin.com/in/jimmowrey/"><unicon height="20" width="20" fill="white" class="sales-social" name="linkedin-alt"></unicon></a></li>
                                          </ul>
                                      </div>
                                  </div>
                              </figure>
                              <div class="author-info">
                                  <h4>Jim Mowrey</h4>
                                  <p>Territory: All Regions</p>
                                  <ul>
                                      <li>Tel: 570-560-3122</li>
                                      <li>
                                        <a href="mailto:jmowrey@repsales.net">Email Me</a>
                                      </li>
                                  </ul>
                              </div>
                          </div>
                          <div class="member">
                              <figure>
                                  <img src="img/rkilgore.jpg" alt="Sales - IL, IN, W. KY" />
                                  <div class="overlay">
                                      <div class="inner-box">
                                          <ul class="social">
                                            <li><a href="https://www.facebook.com/ryan.kilgore.942"><unicon height="20" width="20" fill="white" class="sales-social" name="facebook-f"></unicon></a></li>
                                              <li><a href="https://www.linkedin.com/in/ryan-kilgore-3275b6b0/"><unicon height="20" width="20" fill="white" class="sales-social" name="linkedin-alt"></unicon></a></li>
                                          </ul>
                                      </div>
                                  </div>
                              </figure>
                              <div class="author-info">
                                  <h4>Ryan Kilgore</h4>
                                  <p>Territory: IL, IN, W. KY</p>
                                  <ul>
                                      <li>Tel: 618-302-0445</li>
                                      <li><a href="mailto:ryan@repsales.net">Email Me</a></li>
                                  </ul>
                              </div>
                          </div>
                          <div class="member">
                              <figure>
                                  <img src="img/rusty.png" alt="Sales - OH, WV, W. PA, E. KY" />
                                  <div class="overlay">
                                      <div class="inner-box">
                                          <ul class="social">
                                            <li><a href="https://www.facebook.com/rusty.howell.96"><unicon height="20" width="20" fill="white" class="sales-social" name="facebook-f"></unicon></a></li>
                                              <li><a href="https://www.linkedin.com/in/rusty-howell-377b4592/"><unicon height="20" width="20" fill="white" class="sales-social" name="linkedin-alt"></unicon></a></li>
                                          </ul>
                                      </div>
                                  </div>
                              </figure>
                              <div class="author-info">
                                  <h4>Rusty Howell</h4>
                                  <p>Territory: OH, WV, W. PA, E. KY</p>
                                  <ul>
                                      <li>Tel: 330-340-2176</li>
                                      <li><a href="mailto:rusty@repsales.net">Email Me</a></li>
                                  </ul>
                              </div>
                          </div>
<!--                          <div class="member">-->
<!--                              <figure>-->
<!--                                  <img src="img/atucker.jpg" alt="Sales - MI" />-->
<!--                                  <div class="overlay">-->
<!--                                      <div class="inner-box">-->
<!--                                          <ul class="social">-->
<!--                                            <li><a href="https://www.facebook.com/andy.tucker.79"><unicon height="20" width="20" fill="white" class="sales-social" name="facebook-f"></unicon></a></li>-->
<!--                                              <li><a href="https://www.linkedin.com/in/andy-tucker-034b1036/"><unicon height="20" width="20" fill="white" class="sales-social" name="linkedin-alt"></unicon></a></li>-->
<!--                                          </ul>-->
<!--                                      </div>-->
<!--                                  </div>-->
<!--                              </figure>-->
<!--                              <div class="author-info">-->
<!--                                  <h4>Andy Tucker</h4>-->
<!--                                  <p>Territory: MI</p>-->
<!--                                  <ul>-->
<!--                                      <li>Tel: 231-384-2190</li>-->
<!--                                      <li><a href="mailto:andy@repsales.net">Email Me</a></li>-->
<!--                                  </ul>-->
<!--                              </div>-->
<!--                          </div>-->
                      </div>
                  </div>
              </div>
          </div>
      </section>
</template>

<script>
export default {
    name: 'SalesTeam'
}
</script>

<style lang="scss" scoped>
  .sales-social {
    padding-top: .3em;
  }
  .our-team {
    .title {
      margin-bottom: 70px;
      h2 {
        text-align: left;
        margin: 0;
        font-size: 28px;
        color: #252525;
        font-family: 'Poppins', sans-serif;
        font-weight: 800;
        &:before {
          content: "";
          position: absolute;
          top: 50px;
          left: 15px;
          width: 50px;
          height: 4px;
          background: $orange;
        }
      }
    }
    padding: 80px 0;
    h2 {
      text-align: left;
      margin: 0;
      font-size: 28px;
      color: #252525;
      font-family: 'Poppins', sans-serif;
      font-weight: 800;
      &:before {
        content: "";
        position: absolute;
        top: 50px;
        left: 15px;
        width: 50px;
        height: 4px;
        background: $orange;
      }
    }
    .team-text {
      p {
        text-align: left;
        margin: 0;
        font-size: 14px;
        margin-top: 40px;
        font-family: 'Poppins', sans-serif;
        color: #3d3d3d;
        font-weight: 300;
        line-height: 25px;
      }
      a.btn {
        padding: 10px 29px;
        color: $orange;
        border: 1px solid #cdcdcd;
        margin-top: 25px;
        font-family: 'Poppins', sans-serif;
        font-weight: 400;
      }
    }
    .members {
      .member {
        width: 250px;
        display: inline-block;
        margin-right: 28px;
        figure {
          width: 250px;
          height: 260px;
          overflow: hidden;
          position: relative;
          .overlay {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            background: rgba(251, 173, 26, .8);
            transform: scale(0.7, 0);
            transition: .5s ease-in-out;
            .inner-box {
              display: table;
              width: 100%;
              height: 100%;
              text-align: center;
              .social {
                list-style-type: none;
                display: table-cell;
                vertical-align: middle;
                position: relative;
                right: 16px;
                transition: .2s cubic-bezier(0.4, 0, 1, 1);
                li {
                  display: inline-block;
                  margin: 0 10px 0 0;
                  font-size: 14px;
                  padding: 0;
                  a {
                    height: 40px;
                    width: 40px;
                    color: #FFFFFF;
                    display: block;
                    border-radius: 50%;
                    border: 1px solid #FFFFFF;
                    text-align: center;
                    line-height: 40px;
                  }
                }
              }
            }
          }
        }
        &:hover {
          figure {
            .overlay {
              transform: scale(1, 1);
              transition: .5s ease-in-out;
            }
          }
        }
        .author-info {
          position: relative;
          border: 1px solid #f4f4f4;
          padding: 15px 15px;
          transition: .5s ease;
          h4 {
            text-align: left;
            margin: 0;
            font-size: 17px;
            color: #363636;
            font-family: 'Poppins', sans-serif;
            font-weight: 600;
          }
          p {
            text-align: left;
            margin-top: 5px;
            font-size: 13px;
            color: $orange;
            font-family: 'Poppins', sans-serif;
            font-weight: 300;
          }
          ul {
            list-style-type: none;
            padding-left: 0;
            font-family: 'Poppins', sans-serif;
            font-weight: 300;
            margin-bottom: 0;
            padding-top: 10px;
            border-top: 1px solid #f4f4f4;
            color: #707070;
          }
        }
      }
    }
  }

</style>