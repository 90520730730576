<template>
  <div id="app">
    <SiteHeader />
    <div id="home-container">
      <transition name="fade" mode="out-in">
        <router-view></router-view>
      </transition>
      <SiteFooter/>
    </div>
  </div>
</template>

<script>
import SiteHeader from './components/structure/SiteHeader.vue'
import SiteFooter from './components/structure/SiteFooter.vue'

export default {
  name: 'App',
  components: {
    SiteHeader,
    SiteFooter,
  }
}
</script>

<style lang="scss">
  @import './assets/css/hover.css';
  @import './assets/css/bootstrap.min.css';
  @import './assets/css/responsive.css';
  @import url('https://fonts.googleapis.com/css?family=Poppins:300,400,500,600,700');
  @import url('https://fonts.googleapis.com/css?family=Open+Sans:400,300,300italic,400italic,600,600italic,700,700italic,800,800italic');
  @import url('https://fonts.googleapis.com/css?family=Open+Sans|Roboto');
  @import url('https://fonts.googleapis.com/css?family=Open+Sans|PT+Serif|Roboto');
  @import url('https://fonts.googleapis.com/css?family=Open+Sans|PT+Serif|Raleway:400,600,700,800|Roboto:400,700,900');

  @keyframes fadeItIn {
    from {
      opacity: 0;
      -webkit-transform: translate3d(0, -100%, 0);
      -moz-transform: translate3d(0, -100%, 0);
      transform: translate3d(0, -100%, 0);
    }
    to {
      opacity: 1;
      -webkit-transform: none;
      -moz-transform: none;
      transform: none;
    }
  }
  .offer-wrapper {
    .img-box {
      position: relative;
      height: 210px;
      overflow: hidden;
    }
    .item {
      .btn {
        border: none;
        background: #FA6300;
        color: #ffffff;
        border-radius: 0;
        text-transform: uppercase;
        font-size: 14px;
        font-weight: bold;
        padding: 11px 25px;
        position: absolute;
        top: 85px;
        left: 120px;
      }
      h3 {
        font-size: 18px;
        font-family: 'Poppins', sans-serif;
        font-weight: 600;
      }
      p {
        color: #707070;
        line-height: 25px;
        margin-bottom: 15px;
        font-weight: 300;
      }
      h6 {
        a {
          font-size: 14px;
          font-family: 'Poppins', sans-serif;
          font-weight: 600;
          color: #FA6300;
        }
      }
      &:hover {
        .default-overlay-outer {
          top: 0;
          opacity: 1;
        }
      }
      .default-overlay-outer {
        opacity: 0;
        top: -100%;
        background: rgba(16, 37, 46, 0.7);
      }
    }
    .second {
      margin-top: 40px;
    }
    padding: 80px 0;
    h2 {
      text-align: center;
      margin: 0;
      font-size: 28px;
      color: #252525;
      font-family: 'Poppins', sans-serif;
      font-weight: 800;
      &:before {
        content: "";
        position: absolute;
        top: 50px;
        left: 48%;
        width: 50px;
        height: 4px;
        background: #FA6300;
      }
    }
    .title {
      position: relative;
      margin-bottom: 80px;
    }
  }
  .default-overlay-outer {
    position: absolute;
    display: block;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    padding: 10px;
    transition: all 700ms ease;
    -webkit-transition: all 700ms ease;
    -ms-transition: all 700ms ease;
    -o-transition: all 700ms ease;
    -moz-transition: all 700ms ease;
    .inner {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      display: table;
      vertical-align: middle;
      text-align: center;
    }
  }
  a {
    text-decoration: none;
    &:hover {
      text-decoration: none;
    }
    &:active {
      text-decoration: none;
    }
  }
  body {
    font-family: 'Poppins', sans-serif;
    width: 100%;
    color: black;
    margin: 0 auto;
    position: relative;
    background: #fff;
    font-weight: normal;
    font-style: normal;
    font-size: 1.6em;
    line-height: 1.5;
  }
  .fade-enter-active {
    transition: opacity .2s;
  }
  .fade-leave-active {
    transition: opacity .2s;
  }
  .fade-enter {
    opacity: 0;
  }
  .fade-leave-to {
    opacity: 0;
  }

</style>
