<template>
  <section class="offer-wrapper">
    <div class="container">
      <div class="row text-center">
        <img src="/img/rsi-olney.png" alt="Olney, Illinois Warehouse"/>
      </div>
      <div class="row" style="padding: 1em;">
        <div class="item">
          <div class="text-center" style="padding: 2em 0 2em 0">
            <h1>Experience Matters</h1>
          </div>

          <div class="row">
            <div class="col-md-6">
              <div class="text-center">
                <unicon name="qrcode-scan" height="64" width="64" fill="black"></unicon>
              </div>

              Our team has over 100 years of combined experience in the industry. We know what works and what doesn't,
              we know the name for that part you're trying to find, and we know who might have one on the shelf.

            </div>
            <div class="col-md-6">
              <div class="text-center">
                <unicon name="box" height="64" width="64" fill="black"></unicon>
              </div>

              We have existing relationships with hundreds of energy industry suppliers, wholesalers, and manufacturers.
              Let us help bridge your gaps: call us with any need, and we'll help you find a solution.

            </div>
          </div>

          <br/>
          <hr>
          <br/>


          <div class="row">

            <div class="col-md-4 text-center">
              <div>
                <unicon name="truck" height="64" width="64" fill="brown"></unicon>
              </div>
              <span style="font-size: 2em">
                <strong>FREE Scheduled Delivery</strong>
              </span>
              <br/>
              <br/>
              Call the nearest warehouse to place an order or check the schedule!
              If it fits on our truck, it rides for free. All for the low, low cost
              of a sales visit.
            </div>

            <div class="col-md-4 text-center">
              <div>
                <unicon name="truck" height="64" width="64" fill="brown"></unicon>
              </div>
              <span style="font-size: 2em">
                <strong>FREE Product Research</strong>
              </span>
              <br/>
              <br/>
              Send us a description, a picture, a part number, or a drawing and we'll help source it for you.
            </div>


            <div class="col-md-4 text-center">
              <div>
                <unicon name="truck" height="64" width="64" fill="brown"></unicon>
              </div>
              <span style="font-size: 2em">
                <strong>FREE Estimates & Quotations</strong>
              </span>
              <br/>
              <br/>
              800-274-2003 is all you need to get a quote on any product or service we offer.
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'ExperienceMatters'
}
</script>

<style lang="scss" scoped>
.shipping-icon {
  opacity: 0.3;
}
</style>