<template>
    <section class="offer-wrapper" id="learn_more">
          <div class="container">
              <div class="row">
                  <div class="col-md-4 col-sm-4 col-xs-12">
                      <div class="item">
                          <figure class="img-box">
                              <img src="img/rsi-olney.png" loading="lazy" alt="Olney, Illinois Warehouse" />
                              <figcaption class="default-overlay-outer">
                                  <div class="inner">
                                      <div class="content-layer">
                                        <router-link class="this-link btn btn-primary" to="/delivery">
                                          FREE Delivery
                                        </router-link>
                                      </div>
                                  </div>
                              </figcaption>
                          </figure>
                          <h3>Local Warehouse & Delivery</h3>
                          <p>
                            Free, regularly-scheduled delivery? Check. Daily truck or UPS shipments? Check.
                            Freight allowed terms available? Call us. 
                          </p>
                          <h6><router-link to="/delivery">Read More</router-link></h6>
                      </div>
                  </div>
                  <div class="col-md-4 col-sm-4 col-xs-12">
                      <div class="item">
                          <figure class="img-box">
                              <img src="img/working-hands.jpg" loading="lazy"  alt="image" />
                              <figcaption class="default-overlay-outer">
                                  <div class="inner">
                                      <div class="content-layer">
                                        <router-link class="this-link btn btn-primary" to="/experience">Broad Reach</router-link>
                                      </div>
                                  </div>
                              </figcaption>
                          </figure>
                          <h3>Experience Matters</h3>
                          <p>
                            With decades of experience and thousands of contacts, we can find the exotic or rare stuff you need
                            to get things done. Not sure what it is? Send us a picture and we'll help sort it out.
                          </p>
                          <h6><a href="/experience">Read More</a></h6>
                      </div>
                  </div>
                  <div class="col-md-4 col-sm-4 col-xs-12">
                      <div class="item">
                          <figure class="img-box">
                              <img src="img/warehouse-shelves.jpg" loading="lazy"  alt="image" />
                              <figcaption class="default-overlay-outer">
                                  <div class="inner">
                                      <div class="content-layer">
                                        <router-link class="this-link btn btn-primary" to="/manufacturers">Partners</router-link>
                                      </div>
                                  </div>
                              </figcaption>
                          </figure>
                          <h3>Representing Manufacturers</h3>
                          <p>
                            We bring products to market for {{ manufacturers.length }} manufacturers. That said, we have relationships
                            with hundreds of manufacturers. We can find what you need.
                          </p>
                          <h6><a href="/manufacturers">Read More</a></h6>
                      </div>
                  </div>
<!--                  <div class="col-md-4 col-sm-4 col-xs-12">-->
<!--                      <div class="item">-->
<!--                          <figure class="img-box">-->
<!--                              <img src="img/devices.jpg" loading="lazy"  alt="image" />-->
<!--                              <figcaption class="default-overlay-outer">-->
<!--                                  <div class="inner">-->
<!--                                      <div class="content-layer">-->
<!--                                        <router-link class="this-link btn btn-primary" to="/personal">Small Business</router-link>-->
<!--                                      </div>-->
<!--                                  </div>-->
<!--                              </figcaption>-->
<!--                          </figure>-->
<!--                          <h3>The Personal Touch</h3>-->
<!--                          <p>-->
<!--                            As a small business, we know nothing's more important than personal service. We pride ourselves-->
<!--                            on offering something unique in our territory: friendly, helpful service.-->
<!--                          </p>-->
<!--                          <h6><a href="/personal">Read More</a></h6>-->
<!--                      </div>-->
<!--                  </div>-->
              </div>
              <div class="row second">

<!--                  <div class="col-md-4 col-sm-4 col-xs-12">-->
<!--                      <div class="item">-->
<!--                          <figure class="img-box">-->
<!--                              <a href="#"><img src="img/shaking-hands.jpg" loading="lazy" alt="image" /> </a>-->
<!--                              <figcaption class="default-overlay-outer">-->
<!--                                  <div class="inner">-->
<!--                                      <div class="content-layer">-->
<!--                                        <router-link class="this-link btn btn-primary" to="/relationships">Clients</router-link>-->
<!--                                      </div>-->
<!--                                  </div>-->
<!--                              </figcaption>-->
<!--                          </figure>-->
<!--                          <h3>It's about relationships</h3>-->
<!--                          <p>-->
<!--                            We can introduce your products to hundreds of retail and wholesale establishments. Leverage our-->
<!--                            relationships to advance your sales goals.-->
<!--                          </p>-->
<!--                          <h6><a href="/relationships">Read More</a></h6>-->
<!--                      </div>-->
<!--                  </div>-->
<!--                  <div class="col-md-4 col-sm-4 col-xs-12">-->
<!--                      <div class="item">-->
<!--                          <figure class="img-box">-->
<!--                              <a href="#"><img src="img/devices-2.png" loading="lazy"  alt="image" /> </a>-->
<!--                              <figcaption class="default-overlay-outer">-->
<!--                                  <div class="inner">-->
<!--                                      <div class="content-layer">-->
<!--                                        <router-link class="this-link btn btn-primary" to="#">See more</router-link>-->
<!--                                      </div>-->
<!--                                  </div>-->
<!--                              </figcaption>-->
<!--                          </figure>-->
<!--                          <h3>Get Answers Now</h3>-->
<!--                          <p>-->
<!--                            Call, fax, email, or text whenever you have a question and just need a quick answer.-->
<!--                          </p>-->
<!--                          <h6><router-link to="/contact">We're Ready</router-link></h6>-->
<!--                      </div>-->
<!--                  </div>-->
              </div>
          </div>
      </section>
</template>

<script>

import manufacturers from '../assets/js/manufacturers.js'

export default {
    name: 'LearnMore',
    data() {
      return {
        manufacturers: manufacturers
      }
    }
}
</script>

<style lang="scss" scoped>

</style>