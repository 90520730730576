import Vue from 'vue';
import App from './App.vue';
import VueRouter from 'vue-router';
import router from './routes.js';
import axios from 'axios';
import VueCarousel from 'vue-carousel';
import Unicon from './assets/js/useUnicons';

Vue.config.productionTip = false;
Vue.prototype.$http = axios;

// Because sometimes we need this outside of CSS.
window.orange = '#FA6300';
window.blue   = '#131F33';

Vue.use(Unicon,{
  fill: "royalblue",
  height: 20,
  width: 20
});

Vue.use(VueRouter);
Vue.use(VueCarousel);

new Vue({
  router,
  render: h => h(App),
}).$mount('#app');

