
<template>
  <div>
    <div v-if="to.startsWith('http')">
      <a :href="to" :target="target" class="bounce-right slide-button"><slot></slot></a>
    </div>
    <div v-else>
      <router-link :to="to" class="bounce-right slide-button"><slot></slot></router-link>
    </div>
  </div>
</template>

<script>
export default {
    name: 'ButtonPrimary',
    props: {
        to: String,
        target: {
          type: String,
          required: false,
          default: "_blank"
        }
    }
}
</script>

<style lang="scss" scoped>
/* Bounce To Right */
.bounce-right {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px transparent;
  position: relative;
  -webkit-transition-property: color;
  transition-property: color;
  -webkit-transition-duration: 0.5s;
  transition-duration: 0.5s;
  color:#FFFFFF;
}
.bounce-right:before {
  content: "";
  position: absolute;
  color:#FFFFFF;
  z-index: -1;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: $orange;
  -webkit-transform: scaleX(0);
  transform: scaleX(0);
  -webkit-transform-origin: 0 50%;
  transform-origin: 0 50%;
  -webkit-transition-property: transform;
  transition-property: transform;
  -webkit-transition-duration: 0.5s;
  transition-duration: 0.5s;
  -webkit-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
}
.bounce-right:hover, .bounce-right:focus, .bounce-right:active {
  color:#FFFFFF;
}
.bounce-right:hover:before, .bounce-right:focus:before, .bounce-right:active:before {
  -webkit-transform: scaleX(1);
  transform: scaleX(1);
  -webkit-transition-timing-function: cubic-bezier(0.52, 1.64, 0.37, 0.66);
  transition-timing-function: cubic-bezier(0.52, 1.64, 0.37, 0.66);
}
.slide-button {
  padding: 12px 33px;
  background: #072D4D;
  color: #ffffff;
  border: none;
  margin-top: 25px;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  margin-right: 20px;
}

</style>