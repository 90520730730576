<template>
  <section class="offer-wrapper" id="learn_more">
    <div class="container">
      <div
        class="col-md-3 manufacturer-summary"
        :key="manufacturer.abbr"
        v-for="manufacturer in manufacturers"
      >
        <ManufacturerListCard v-bind="manufacturer" />
      </div>
    </div>
  </section>
</template>

<script>
import ManufacturerListCard from "./ManufacturerListCard.vue";
import manufacturers from "../../assets/js/manufacturers.js";

export default {
  name: "ManufacturerList",
  components: {
    ManufacturerListCard,
  },
  data: function () {
    return {
      manufacturers: manufacturers,
    };
  },
};
</script>

<style lang="scss" scoped>
  .manufacturer-summary {
    height: 16em;
    text-align: center;
    padding: 2em;
  }
  .container {
    padding-top: 0;
    position: relative;
    top: -4em;
  }
</style>