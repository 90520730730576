<template>
  <section class="contact" id="contact_us">
    <div class="container">
      <div class="row">
        <div class="contact-wrapper">
          <div class="col-md-6 nopd">
            <div class="contact-info">
              <h2>Request Information</h2>
              <p>
                We'll reply to your message as soon as possible. Please note that it
                might take us a day or two to respond to general information requests.
                We move all RFQ/quotation requests to the top of the stack, so those
                should be answered promptly.
              </p>
              <p>
                If you need answers now, please call us at <strong>800-274-2003</strong>.
              </p>
            </div>
          </div>
          <div class="col-md-6">
            <form id="request-call-form" class="form" action="" method="POST">
              <input type="hidden" name="token" v-model="token"/>
              <input type="hidden" name="domain" v-model="domain"/>
              <div class="row">
                <div class="form-group">
                  <div class="col-md-6">
                    <input id="name" type="text" v-model="name" placeholder="Name" name="name" aria-label="Your Name">
                  </div>
                  <div class="col-md-6">
                    <input id="email" type="text" v-model="email" placeholder="Email" name="email"
                           aria-label="Email Address">
                  </div>
                </div>
                <div class="form-group">
                  <div class="col-md-6">
                    <input id="phone" type="text" v-model="phone" placeholder="Phone" name="phone"
                           aria-label="Phone Number">
                  </div>
                  <div class="col-md-6">
                    <select class="form-control select" v-model="subject" name="subject" title="Select An Option">
                      <option :key="option.text" v-for="option in options" :value="option.text">
                        {{ option.text }}
                      </option>
                    </select>
                  </div>
                </div>
                <div class="form-group">
                  <div class="col-md-12">
                    <textarea class="form-control" aria-label="Message to Us" v-model="body" name="body" cols="40"
                              rows="12"
                              placeholder="Message" id="inputComments"></textarea>
                  </div>
                </div>
                <div class="form-group">
                  <div class="col-md-4 col-md-offset-8">
                      <button type="button" @click="handleFormSubmit" aria-label="Submit contact request button"
                              :disabled="formButtonDisabled"
                              class="btn btn-primary hvr-bounce-to-right" id="contact_submitted_button">Send Request
                      </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'ContactUs',
  data: function () {
    return {
      name: '',
      email: '',
      subject: 'General Information',
      phone: '',
      body: '',
      domain: 'repsales.net',
      token: "SxDt8qXpuWDgSUQxrzl7D3hkyujnk46j04cgqykBXm8fqw0D",
      formButtonDisabled: false,
      options: [
        {
          "text": "Request for Quotation",
        },
        {
          "text": "General Information",
        },
        {
          "text": "Technical Data Request",
        },
        {
          "text": "Schedule a Meeting",
        },
        {
          "text": "Office Pool Info Request",
        },
      ],
    }
  },
  methods: {
    handleFormSubmit: async function () {

      this.formButtonDisabled = true;

      let url = 'https://api.zbmowrey.com/mail';

      let data = {
        subject: "Web Contact: " + this.subject,
        body: this.body,
        token: this.token,
        domain: this.domain,
        phone: this.phone,
        email: this.email,
        name: this.name,
      };

      let response = await this.$http.post(url, data);
      if (response.status !== 200 || (response.data && response.data.statusCode !== 200)) {
        console.error("Got an error sending email.", JSON.stringify(response));
      }

      this.formButtonDisabled = false;
    },
  }
}
</script>

<style lang="scss" scoped>
.contact {
  padding: 80px 0;

  h2 {
    text-align: left;
    margin-bottom: 30px;
    margin-top: 0;
    font-size: 28px;
    color: #252525;
    font-family: 'Poppins', sans-serif;
    font-weight: 800;

    &:before {
      content: "";
      position: absolute;
      top: 100px;
      left: 20px;
      width: 50px;
      height: 4px;
      background: $orange;
    }
  }

  p {
    text-align: left;
    margin: 0;
    font-size: 14px;
    color: #252525;
    font-family: 'Poppins', sans-serif;
    font-weight: 300;
    margin-top: 19px;
    line-height: 30px;
  }

  .contact-wrapper {
    background: #f2f2f2;
    position: relative;
    padding: 20px;
    overflow: hidden;

    .nopd {
      padding-left: 0;
    }

    .contact-info {
      padding: 60px 15px 80px 20px;
      background: #FFFFFF;
    }
  }

  form {
    margin-top: 30px;

    input[type="text"] {
      width: 100%;
      height: 48px;
      padding: 10px 10px;
      border: 1px solid #6d6d6d;
      border-radius: 0;
      background: #FFFFFF;
      margin-bottom: 12px;
      font-family: 'Poppins', sans-serif;
      font-weight: 300;
      color: #999999;
    }

    select {
      width: 100%;
      height: 48px;
      padding: 10px 10px;
      border: 1px solid #6d6d6d;
      border-radius: 0;
      background: #FFFFFF;
      margin-bottom: 12px;
      font-family: 'Poppins', sans-serif;
      font-weight: 300;
      color: #999999;
    }

    textarea {
      max-width: 100%;
      max-height: 130px;
      min-height: 130px;
      padding: 10px 10px;
      border: 1px solid #6d6d6d;
      border-radius: 0;
      background: #FFFFFF;
      margin-bottom: 20px;
      font-family: 'Poppins', sans-serif;
      font-weight: 300;
      color: #999999;
      outline: none;
    }

    a.btn {
      padding: 10px 29px;
      background: $orange;
      color: #FFFFFF;
      border: none;
      border-radius: 0;
      font-family: 'Poppins', sans-serif;
      font-weight: 400;
      margin-left: 15px;
    }
  }
}

</style>
