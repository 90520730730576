<template>
  <section class="partners">
    <div class="container">
      <div class="row">
        <div class="col-md-12">
          <h2>Our partners</h2>
        </div>
        <div class="col-md-12">
          <div id="about-us-our-partners">
            <Carousel v-bind="carouselProps">
              <Slide :key="manufacturer.id" v-for="manufacturer in manufacturers">
                <a :href="manufacturer.url">
                  <img class="img-responsive"
                       :src="getLogoUrl(manufacturer)"
                       :alt="manufacturer.name"></a>
              </Slide>
            </Carousel>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import {Carousel, Slide} from 'vue-carousel'
import carouselProps from '../assets/js/carouselProps.js'
import manufacturers from '../assets/js/manufacturers.js'

export default {
  name: 'PartnersCarousel',
  components: {
    Carousel,
    Slide,
  },
  beforeCreate() {
    carouselProps.autoplay = true;
  },
  methods: {
    getLogoUrl(manufacturer) {
      return '/img/partners/' + manufacturer.abbr + '/' + manufacturer.abbr + '-small.png';
    }
  },
  data() {
    return {
      manufacturers: manufacturers,
      carouselProps: carouselProps,
    }
  }
}
</script>

<style lang="scss" scoped>

.partners {
  padding: 80px 0;
  background: #f2f2f2;
}

.partners h2 {
  text-align: left;
  margin-bottom: 30px;
  margin-top: 0;
  font-size: 28px;
  color: #252525;
  font-family: 'Poppins', sans-serif;
  font-weight: 800;
}

.partners h2:before {
  content: "";
  position: absolute;
  top: 50px;
  left: 20px;
  width: 50px;
  height: 4px;
  background: $orange;
}

.partners #about-us-our-partners {
  margin-top: 30px;
}

.partners .image {
  border: 1px solid #cacaca;
  width: 240px;
  height: 110px;
  padding: 10px;
  background: #FFFFFF;
}
</style>