<template>
  <section class="main-menu-one finance-navbar" :class="{sticky:sticky}">
    <div class="container">
      <div class="row">
        <div class="col-md-9">
          <nav id="main-navigation-wrapper" class="navbar navbar-default">
            <div class="navbar-header">
              <button type="button" data-toggle="collapse" data-target="#main-navigation" aria-expanded="false"
                      class="navbar-toggle collapsed">
                <span class="sr-only">Toggle navigation</span><span class="icon-bar"></span><span
                  class="icon-bar"></span><span class="icon-bar"></span>
              </button>
            </div>
            <div id="main-navigation" class="collapse navbar-collapse">
              <ul class="nav navbar-nav navbar-left">
                <li>
                  <router-link class="nav-menu-link" to="/">Home</router-link>
                </li>
                <li>
                  <router-link class="nav-menu-link" to="/about/company">About</router-link>
                  <ul class="dropdown-submenu">
                    <li>
                      <router-link to="/about/team">Team Members</router-link>
                    </li>
                    <li>
                      <router-link to="/about/company">REP Sales</router-link>
                    </li>
                  </ul>
                </li>
                <li>
                  <router-link class="nav-menu-link" to="/manufacturers">Manufacturers</router-link>
                  <ul class="dropdown-submenu">
                    <li>
                      <router-link to="/manufacturers">Line Sheet</router-link>
                    </li>
                  </ul>
                </li>
                <li>
                  <router-link class="nav-menu-link" to="#">Office Pools</router-link>
                  <ul class="dropdown-submenu">
                    <li>
                      <router-link to="/pools/football">
                        <img
                            src="/img/football-icon.png"
                            width="16"
                            alt="football icon"
                        >
                        Football
                        <span class="pull-right label label-default" :class="{poolRunning: footballSeason}" style="margin-right: 5px">
                          2024
                        </span>
                      </router-link>
                    </li>
<!--                    <li>-->
<!--                      <router-link to="/pools/basketball"><img src="/img/basketball-icon.png" alt="basket ball icon">-->
<!--                        Basketball-->
<!--                        <span class="pull-right label label-default" :class="{poolRunning: basketballSeason}" style="margin-right: 5px">-->
<!--                          2024-->
<!--                        </span>-->
<!--                      </router-link>-->
<!--                    </li>-->
                  </ul>
                </li>
                <li class="download">
                  <a class="nav-menu-link" href="/line_card.pdf">
                    <div class="pull-right">
                      Printable Line Card
                    </div>
                  </a>
                </li>
              </ul>
            </div>
          </nav>
        </div>
      </div>
    </div>
  </section>
</template>

<script>

export default {
  name: 'NavBar',
  data() {
    return {
      sticky: false,
    }
  },
  computed: {
    basketballSeason: () => {
      const date = new Date;
      const seasonStart = '2023-03-01';
      const seasonEnd = '2023-04-01';
      return Date.parse(seasonStart) < date && date < Date.parse(seasonEnd);
    },
    footballSeason: () => {
      const date = new Date;
      const seasonStart = '2023-08-28';
      const seasonEnd = '2024-02-01';
      return Date.parse(seasonStart) < date && date < Date.parse(seasonEnd);
    },
  },
  deactivated() {
    window.removeEventListener('scroll', this.handleScroll)
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll)
  },
  methods: {
    handleScroll() {
      const topDistance = (window.pageYOffset !== undefined)
          ? window.pageYOffset
          : (document.documentElement || document.body.parentNode || document.body).scrollTop;
      this.sticky = topDistance > 200;
    }
  }
}
</script>

<style lang="scss" scoped>
.nav-menu-link {
  &:hover {
    color: #e75930 !important;
  }
}

#main-navigation-wrapper.navbar-default {
  background: transparent;
  border: none;
  border-radius: 0;
  margin: 0;
}

#main-navigation-wrapper {
  .nav {
    > li {
      padding: 25px 0;

      > a {
        color: #FFFFFF;
        text-transform: uppercase;
        display: block;
        padding: 0 26px;
        font-weight: bold;
        margin: 0;
      }

      &:first-child {
        a {
          padding-left: 0;
        }
      }
    }

    .dropdown-submenu {
      li {
        &:last-child {
          border-bottom: 4px solid $orange;
          border-bottom: 4px solid $orange;

          > a {
            border-bottom: none;
            border-bottom: none;
          }
        }

        a {
          display: block;
          border-bottom: 1px solid #ebebeb;
          text-transform: capitalize;
          color: #313131;
          font-size: 15px;
          font-weight: 500;
          padding: 12px 0 12px 33px;
          line-height: normal;
          transition: all 0.4s ease 0s;
          margin: 0;

          &:hover {
            background: $orange;
            color: #FFFFFF;
            text-decoration: none;
          }
        }
      }
    }
  }

  .navbar-nav {
    > li {
      > .dropdown-submenu {
        position: absolute;
        top: 100%;
        left: 0;
        z-index: 1000;
        float: left;
        min-width: 160px;
        font-size: 14px;
        text-align: left;
        list-style: none;
        background-clip: padding-box;
        padding: 0;
        border: none;
        border-radius: 0;
        display: block;
        opacity: 0;
        visibility: hidden;
        -webkit-transition: all .4s ease;
        transition: all .4s ease;
        width: 270px;
        -webkit-transform: translate3d(-20px, 0, 0);
        transform: translate3d(-20px, 0, 0);
        background: #fdfdfd;
      }

      &:hover {
        > .dropdown-submenu {
          opacity: 1;
          visibility: visible;
          -webkit-transform: translate3d(0px, 0, 0);
          transform: translate3d(0px, 0, 0);
        }
      }
    }

    > .open {
      > .dropdown-submenu {
        opacity: 1;
        visibility: visible;
        -webkit-transform: translate3d(0px, 0, 0);
        transform: translate3d(0px, 0, 0);
      }
    }

    li {
      &:hover {
        .dropdown-submenu {
          opacity: 1;
          visibility: visible;
          -webkit-transform: translate3d(0px, 0, 0);
          transform: translate3d(0px, 0, 0);
        }
      }
    }
  }
}

.main-menu-one {
  background: $blue;

  #main-navigation-wrapper {
    .navbar-nav {
      li {
        &:hover {
          .dropdown-submenu {
            opacity: 1;
            visibility: visible;
            -webkit-transform: translate3d(0px, 0, 0);
            transform: translate3d(0px, 0, 0);
          }
        }
      }
    }
  }

  ul.search {
    list-style-type: none;
    overflow: hidden;
    margin: 0;

    li.first {
      margin-left: 55px;
    }
  }
}

.navbar-collapse {
  padding: 0;
}

.navbar-default {
  .navbar-toggle {
    margin-bottom: 25px;
  }
}

.main-menu-one.sticky {
  top: 0;
  left: 0;
  right: 0;
  position: fixed !important;
  margin: 0;
  animation: fadeItIn 1s;
  z-index: 1000;
  min-height: 70px !important;
  background: $blue;
  -webkit-box-shadow: 0 2px 8px -3px rgba(0, 0, 0, 0.47);
  -moz-box-shadow: 0 2px 8px -3px rgba(0, 0, 0, 0.47);
  box-shadow: 0 2px 8px -3px rgba(0, 0, 0, 0.47);
}

.finance-navbar.affix {
  top: 0;
  left: 0;
  right: 0;
  position: fixed !important;
  margin: 0;
  animation: fadeItIn 1s;
  z-index: 1000;
  padding: 5px 0 !important;
  min-height: 70px !important;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}
.poolRunning {
  background: #33AA33;
}


</style>