<template>
  <section class="offer-wrapper">
    <div class="container">
      <div class="row text-center">
        <img src="/img/rsi-olney.png" alt="Olney, Illinois Warehouse"/>
      </div>
      <div class="row" style="padding: 1em;">
        <div class="item">
          <div class="text-center" style="padding: 2em 0 2em 0">
            <h1>Pickup & Delivery Options</h1>
          </div>

          <div class="row">
            <div class="col-md-6">
              <div class="text-center">
                <unicon name="qrcode-scan" height="64" width="64" fill="black"></unicon>
              </div>

              <strong>UPS or Truck Freight</strong> shipments are available on demand. You pay for shipping unless
              otherwise negotiated in advance. We can ship on our accounts or use yours, at your discretion.
            </div>
            <div class="col-md-6">
              <div class="text-center">
                <unicon name="box" height="64" width="64" fill="black"></unicon>
              </div>
              <strong>Pick Up (Will Call)</strong> customers are always welcome! Our loading dock and equipment can
              accommodate large trucks, pickups, and cars. Make arrangements in advance and we'll be ready when you get
              here.
            </div>
          </div>

          <br/>
          <hr>
          <br/>


          <div class="row">

            <div class="col-md-4 text-center">
              <div>
                <unicon name="truck" height="64" width="64" fill="brown"></unicon>
              </div>
              <span style="font-size: 2em">
                <strong>FREE Scheduled Delivery</strong>
              </span>
              <br/>
              <br/>
              Call the nearest warehouse to place an order or check the schedule!
              If it fits on our truck, it rides for free. All for the low, low cost
              of a sales visit.
            </div>


            <div class="col-md-4 text-center">
              <div class="text-center">
                <unicon name="truck" height="64" width="64" fill="brown"></unicon>
              </div>
              <strong>Olney, Illinois</strong> covers:
              <br/>
              Illinois <br>
              Indiana <br>
              Kentucky <br>
              Michigan <br>
              Missouri
            </div>



            <div class="col-md-4 text-center">
              <div class="text-center">
                <unicon name="truck" height="64" width="64" fill="brown"></unicon>
              </div>
              <strong>Midvale, Ohio</strong> covers: <br/>
              Ohio <br>
              Pennsylvania <br>
              West Virginia <br>
              Virginia (NW) <br>
              New York (SW)
            </div>

          </div>

        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'DeliveryService'
}
</script>

<style lang="scss" scoped>
.shipping-icon {
  opacity: 0.3;
}
</style>