import { render, staticRenderFns } from "./ManufacturerProfile.vue?vue&type=template&id=ae219206&scoped=true"
import script from "./ManufacturerProfile.vue?vue&type=script&lang=js"
export * from "./ManufacturerProfile.vue?vue&type=script&lang=js"
import style0 from "./ManufacturerProfile.vue?vue&type=style&index=0&id=ae219206&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ae219206",
  null
  
)

export default component.exports