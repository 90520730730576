<template>
  <section class="offer-wrapper" id="learn_more">
    <div class="container">
      <h3>And we're live!</h3>
      <p>
        The NCAA Men's Basketball Tournament is upon us, and we are ready to go! Our previous pool software provider has
        closed their doors, so we've moved to CBS Sports. If you don't already have a login with them, you'll have to
        register, but it's free and easy. You'll benefit by having the latest scores updated as the games finish.
      </p>
      <p class="text-center" style="padding: 3em">
        <a href="https://picks.cbssports.com/college-basketball/ncaa-tournament/bracket/pools/kbxw63b2geydmnjwg4zdm===?pool-join-key=2ca8ebmkg88ask7b057s3gzey5r1ixx7&invited-by=ivxhi4tzhiytkojugaztgmrx&via-medium=copy&ttag=FF24_lk_cbssports_cbb_bpm_copySetupInvite" target="_blank">
          <button class="btn btn-primary" style="font-size: 2em">Register with CBS Sports</button>
        </a>
<!--      <p style="text-align: center;padding-top: 2em;padding-bottom: 2em">-->
<!--        <a href="/pools/basketball/currentstandings.html">-->
<!--          <button class="btn btn-warning" style="font-size: 2em">Current Standings! </button>-->
<!--        </a>-->
<!--      </p>-->
<!--      <h4>Payout Announcements</h4>-->
<!--      <p>-->
<!--        Round 3 has ended and Patrick Gannon is our top-scoring player with 80 points.-->
<!--        He has a phenomenal 176 possible points, and is the only player in our pool to have correctly selected all eight of the Elite Eight.-->
<!--        He will be difficult to unseat, but there is still plenty of hope remaining for our other players.-->
<!--      </p>-->
      </p>
      <h4>Entry Fees</h4>
      <p>
        Entry fee is <span class="icon" style="vertical-align: middle"><unicon name="credit-card"></unicon></span> $10 per bracket <span class="icon" style="vertical-align: middle"><unicon name="credit-card"></unicon></span>, due by the end of Round 2. You may submit as many brackets as you like!
      </p>
      <h3>
        Payout Details:
      </h3>
      <table align="center" width="75%" style="padding-top: 2em">
        <tr style="border-bottom: 1px solid silver;">
          <th style="text-align: center">End of Round</th>
          <th style="text-align: center">Payout for Top Points</th>
          <th style="text-align: center">Points Per Game</th>
        </tr>
        <tr style="border-bottom: 1px solid silver;">
          <td style="text-align: center">1</td>
          <td style="text-align: center">-</td>
          <td style="text-align: center">1</td>
        </tr>
        <tr style="border-bottom: 1px solid silver;">
          <td style="text-align: center">2</td>
          <td style="text-align: center">-</td>
          <td style="text-align: center">2</td>
        </tr>
        <tr style="border-bottom: 1px solid silver;">
          <td style="text-align: center">3 (Elite Eight)</td>
          <td style="text-align: center">20%*</td>
          <td style="text-align: center">4</td>
        </tr>
        <tr style="border-bottom: 1px solid silver;">
          <td style="text-align: center">4</td>
          <td style="text-align: center">-</td>
          <td style="text-align: center">8</td>
        </tr>
        <tr style="border-bottom: 1px solid silver;">
          <td style="text-align: center">5</td>
          <td style="text-align: center">-</td>
          <td style="text-align: center">16</td>
        </tr>
        <tr style="border-bottom: 1px solid silver;">
          <td style="text-align: center">6 (Championship)</td>
          <td style="text-align: center">30% 2nd Place*<br>50% 1st Place*</td>
          <td style="text-align: center">32</td>
        </tr>
      </table>
      <p style="padding-top: 1em; color: #444444;text-align:center; margin: auto; width: 75%">
        <em>
          * Ties unable to be broken will split payout. Overall tie-breaker is the number of total points in the Championship game.
          Secondary tie-breaker, if necessary, will be overall prediction success percentage.
        </em>
      </p>
    </div>
  </section>
</template>

<script>
export default {
    name: 'BasketballPool'
}
</script>

<style lang="scss" scoped>

</style>