<template>
  <section class="our-projects">
    <div class="container">
      <div class="row head">
        <div class="col-md-12">
          <h2>Our Services</h2>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <div id="services">
              <div class="col-md-4">
                <div class="image">
                  <a class="box-offer" href=""> <img src="img/f550.jpg"
                                                     alt="Full-Size Delivery Truck - Free Scheduled Delivery Routes"></a>
                  <div class="overlay">
                    <div class="box">
                      <div class="content">
                        <div class="top">
                          <router-link to="/delivery">
                            <unicon class="circle-icon" name="link" fill="white"></unicon>
                          </router-link>
                        </div>
                        <div class="bottom">
                          <h3>Warehousing & Delivery</h3>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-4">
                <div class="image">
                  <a class="box-offer" href=""> <img src="img/paperwork.jpg"
                                                     alt="Sales & Consulting - Find What You Need"></a>
                  <div class="overlay">
                    <div class="box">
                      <div class="content">
                        <div class="top">
                          <router-link to="/sales">
                            <unicon class="circle-icon" name="link"></unicon>
                          </router-link>
                        </div>
                        <div class="bottom">
                          <h3>Sales & Consulting</h3>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-4">
                <div class="image">
                  <a class="box-offer" href=""> <img src="img/northeast.jpg"
                                                     alt="Northeastern US - IL, IN, KY, MI, OH, PA, WV, NY, VA"></a>
                  <div class="overlay">
                    <div class="box">
                      <div class="content">
                        <div class="top">
                          <router-link to="/territory">
                            <unicon class="circle-icon" name="link"></unicon>
                          </router-link>
                        </div>
                        <div class="bottom">
                          <h3>Rapid Sales Integration&trade;</h3>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>

export default {
  name: 'OurServices',
  components: {
  }
}
</script>

<style lang="scss" scoped>
.circle-icon {
  padding-top: .1em;
}
.our-projects {
  padding: 60px 0;
  background-image: url(../../public/img/bg.jpg);
  h2 {
    text-align: left;
    margin: 0;
    font-size: 28px;
    color: #FFFFFF;
    font-family: 'Poppins', sans-serif;
    font-weight: 800;
    &:before {
      content: "";
      position: absolute;
      top: 50px;
      left: 15px;
      width: 50px;
      height: 4px;
      background: $orange;
    }
  }
  .image {
    position: relative;
    overflow: hidden;
    height: 240px;
    width: 350px;
    .overlay {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background: rgba(0, 0, 0, 0.8);
      -webkit-transform: translate3d(0, 100px, 0);
      transform: translate3d(0, 100px, 0);
      opacity: 0;
      visibility: hidden;
      -webkit-transition: -webkit-transform .4s ease, opacity .4s ease, visibility .4s ease;
      transition: transform .4s ease, opacity .4s ease, visibility .4s ease;
      .box {
        display: table;
        width: 100%;
        height: 100%;
        .content {
          display: table-cell;
          vertical-align: bottom;
        }
      }
      .top {
        text-align: center;
        a {
          display: block;
          margin: 29px auto 20%;
          width: 43px;
          height: 43px;
          border-radius: 50%;
          border: 1px solid #FFFFFF;
          line-height: 43px;
          font-size: 18px;
          color: #fff;
          -webkit-transition: background .4s ease, border-color .4s ease;
          transition: background .4s ease, border-color .4s ease;
          &:hover {
            background: $orange;
            color: #FFFFFF;
          }
        }
      }
      .bottom {
        background: $orange;
        padding: 16px 18px;
        h3 {
          color: #FFFFFF;
          font-size: 18px;
          margin: 0 0 20px;
          text-align: center;
        }
      }
    }
    &:hover {
      .overlay {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
        opacity: 1;
        visibility: visible;
      }
    }
  }
  .head {
    padding-bottom: 80px;
  }
}

</style>