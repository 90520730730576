<template>
  <section class="quote-request">
    <div class="container">
      <div class="row">
        <div class="col-md-9">
          <h4>Order by 4 PM for Same-Day Shipping - Free Scheduled Delivery on Sales Routes</h4>
        </div>
        <div class="col-md-3">
          <a class="btn hvr-bounce-to-right" href="#contact_us">REQUEST A QUOTE</a>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'QuoteRequestCallToAction'
}
</script>

<style lang="scss" scoped>
  .quote-request {
    padding: 40px 0;
    background: $orange;
  }
  .quote-request h4 {
    text-align: left;
    margin-top: 10px;

    font-size: 20px;
    color: #FFFFFF;
    font-family: 'Poppins', sans-serif;
    font-weight: 800;
  }
  .quote-request a.btn {
    padding: 12px 29px;
    background: $orange;
    color: #FFFFFF;
    border-radius: 0;
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
    margin-left: 15px;
    border: 1px solid #FFFFFF;
  }
</style>