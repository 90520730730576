var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('footer',{staticClass:"main-footer"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"row"},[_vm._m(0),_vm._m(1),_c('div',{staticClass:"col-md-4"},[_c('h2',[_vm._v("Get in Touch")]),_c('ul',{staticClass:"contact-info"},[_c('li',[_c('div',{staticClass:"col-xs-2"},[_c('unicon',{attrs:{"name":"location-point","height":"28","width":"28","fill":_vm.orange}})],1),_vm._m(2)]),_c('li',[_c('div',{staticClass:"col-xs-2"},[_c('unicon',{attrs:{"name":"outgoing-call","height":"28","width":"28","fill":_vm.orange}})],1),_vm._m(3)]),_c('li',[_c('div',{staticClass:"col-xs-2"},[_c('unicon',{attrs:{"name":"clock","height":"28","width":"28","fill":_vm.orange}})],1),_vm._m(4)]),_c('li',[_c('div',{staticClass:"col-xs-2"},[_c('unicon',{attrs:{"name":"envelope-alt","height":"28","width":"28","fill":_vm.orange}})],1),_vm._m(5)])])])])])]),_c('CopyrightBar')],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-md-4"},[_c('figure',[_c('img',{staticClass:"img-responsive",attrs:{"src":"/img/rsi-logo-header.png","alt":"awsome image"}})]),_c('p',[_vm._v(" We've been bringing a small business mindset and personal service to the Northeastern Oil & Gas Industry since 1982. We represent manufacturers of PVF and related products. If one of our sources doesn't make it, we can still help find almost anything. Give us a call and let us wow you. ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-md-4 sec-tab"},[_c('h2',[_vm._v("Market Segments")]),_c('div',{staticClass:"footer-news text-center"},[_c('div',{staticClass:"row text-center"},[_c('p',[_c('strong',[_vm._v("Drilling")])])]),_c('div',{staticClass:"row text-center"},[_c('p',[_c('strong',[_vm._v("Production")])])]),_c('div',{staticClass:"row text-center"},[_c('p',[_c('strong',[_vm._v("Transportation")])])]),_c('div',{staticClass:"row text-center"},[_c('p',[_c('strong',[_vm._v("Storage")])])]),_c('div',{staticClass:"row text-center"},[_c('p',[_c('strong',[_vm._v("Safety")])])]),_c('div',{staticClass:"row text-center"},[_c('p',[_c('strong',[_vm._v("Environmental")])])]),_c('div',{staticClass:"row text-center"},[_c('p',[_c('strong',[_vm._v("Flame Management")])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-xs-10"},[_c('div',{staticClass:"address"},[_vm._v("1124 S. Whittle Ave"),_c('br'),_vm._v(" Olney, IL 62450")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-xs-10"},[_c('div',{staticClass:"address"},[_c('a',{attrs:{"href":"https://goo.gl/maps/CgnS3zcWvtv92HHx9"}},[_vm._v("800-274-2003 (Phone)"),_c('br'),_vm._v(" 800-395-FTGS (Fax) ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-xs-10"},[_c('div',{staticClass:"address"},[_vm._v("M to F - 7:00 to 17:00"),_c('br'),_vm._v(" After-Hours Available")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-xs-10"},[_c('div',{staticClass:"address"},[_c('a',{attrs:{"href":"mailto:info@repsales.net"}},[_vm._v("info@repsales.net")])])])
}]

export { render, staticRenderFns }