<template>
  <div>
    <JumboTron/>
    <LearnMore/>
    <OurServices/>
    <OrangeCalloutRSI/>
    <AboutUs/>
    <StatsCounter/>
    <SalesTeam/>
    <ClientTestimonials/>
    <ContactUs/>
    <PartnersCarousel/>
    <QuoteRequestCallToAction/>
  </div>
</template>

<script>
import JumboTron from './JumboTron.vue'
import LearnMore from './LearnMore.vue'
import OurServices from './OurServices.vue'
import OrangeCalloutRSI from './OrangeCalloutRSI'
import AboutUs from './AboutUs.vue'
import StatsCounter from './StatsCounter.vue'
import SalesTeam from './SalesTeam.vue'
import ClientTestimonials from './ClientTestimonials.vue'
import ContactUs from './ContactUs.vue'
import PartnersCarousel from './PartnersCarousel.vue'
import QuoteRequestCallToAction from './QuoteRequestCallToAction.vue'

export default {
  name: 'SiteHome',
  components: {
    JumboTron,
    LearnMore,
    OurServices,
    OrangeCalloutRSI,
    AboutUs,
    StatsCounter,
    SalesTeam,
    ClientTestimonials,
    ContactUs,
    PartnersCarousel,
    QuoteRequestCallToAction,
  }
}
</script>

<style lang="scss" scoped>
#home-container {
  padding: 0px;
  position: absolute;
  width: 100%;
}
</style>
