<template>
    <section class="testimonials">
          <div class="container">
              <div class="row">
                  <div class="col-md-12">
                      <div class="titles">
                          <h2>Testimonials</h2>
                      </div>
                  </div>
              </div>
              <div class="row">
                  <div class="col-md-6">
                      <blockquote>
                          Testimonial Body
                      </blockquote>
                      <div class="testimonials-author">
                          <div class="author-img" style="padding-top: 2em; padding-left: 1em">
                              <unicon name="user-circle" height="24" width="24" fill="$orange"></unicon>
                          </div>
                          <h4>Name <span> Title</span></h4>
                          Date
                          <ul>
                              Star Rating Here
                              <li><i class="fa fa-star" aria-hidden="true"></i></li>
                          </ul>
                      </div>
                  </div>
              </div>
          </div>
      </section>
</template>

<script>
export default {
    name: 'ClientTestimonials'
}
</script>

<style lang="scss" scoped>
  .testimonials {
    padding: 80px 0;
    background: #f2f2f2;
    position: relative;
  }

  .testimonials .titles {
    margin-bottom: 40px;
  }

  .testimonials h2 {
    text-align: left;
    margin: 0;
    font-size: 28px;
    color: #252525;
    font-family: 'Poppins', sans-serif;
    font-weight: 800;
  }

  .testimonials h2:before {
    content: "";
    position: absolute;
    top: 50px;
    left: 15px;
    width: 50px;
    height: 4px;
    background: $orange;
  }

  .testimonials blockquote {
    position: relative;
    margin: 30px 0;
    padding: 40px 40px 40px 40px;
    font-size: 15px;
    line-height: 26px;
    background-color: #FFFFFF;
    border-radius: 5px;
    color: #898989;
    font-family: 'Poppins', sans-serif;
    font-weight: 300;
  }

  .testimonials blockquote:after {
    position: absolute;
    content: " ";
    top: 100%;
    left: 70px;
    height: 0;
    width: 0;
    border: 29px solid rgba(72, 71, 71, 0);
    border-top-color: #ffffff;
    border-right-width: 27px;
    border-bottom-width: 168px;
    border-left-width: 0;

  }

  .testimonials .testimonials-author .author-img {
    width: 75px;
    height: 75px;
    border-radius: 50%;
    overflow: hidden;
    float: left;
    margin-right: 10px;
  }

  .testimonials .testimonials-author h4 {
    font-family: 'Poppins', sans-serif;
    font-weight: 600;
    font-size: 15px;
    padding-top: 14px;
    color: #666666;
    line-height: 24px;
  }

  .testimonials .testimonials-author span {
    font-family: 'Poppins', sans-serif;
    font-weight: 300;
    font-size: 14px;
    color: #666666;
  }

  .testimonials .testimonials-author ul {
    list-style-type: none;
  }

  .testimonials .testimonials-author ul li {
    display: inline-block;
    color: $orange;
  }
</style>