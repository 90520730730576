<template>
    <section class="about">
          <div class="container">
              <div class="row">
                  <div id="text-block" class="col-md-6">
                      <h2>About Us</h2>
                      <p id="first-content-paragraph">
                          REP Sales, Inc. is a full-service wholesale operation and Manufacturers' Representative Agency.
                          Through a combination of consigned-stock and buy/resell operations, we deliver a basket of products
                          to Oil & Gas <strong>Supply Stores</strong>, <strong>Drilling Rigs</strong>, and others in the <strong>Exploration, Production, and Transportation</strong>
                          market segments.
                      </p>
                      <p>
                          We maintain warehouses in <strong>Olney, IL</strong> and <strong>Midvale, OH</strong>. This gives us 1-day ground shipping for most locations
                          in <strong>IL, IN, KY, MI, OH, WV, PA, NY, and VA</strong>, and allows us to run free, regular sales & delivery routes throughout
                          our territory.
                      </p>
                      <p>
                          Through Rapid Sales Integration&trade;, we can make our relationships your relationships. If you've been
                          lacking a presence in the Northeast, calling <strong>800-274-2003</strong> is the first step.
                      </p>
                      <div class="button">
                          <a class="hvr-bounce-to-right btn" href="#">SEE MORE</a>
                      </div>
                  </div>
                  <div class="col-md-6">
                      <div class="picture-wrapper">
                          <div class="first"><img loading="lazy" src="img/pipes.jpg" alt="Business end of a pipe yard" /></div>
                      </div>
                  </div>
              </div>
          </div>
      </section>
</template>

<script>
export default {
    name: 'AboutUs'
}
</script>

<style lang="scss" scoped>
  .about {
    padding: 80px 0;
    h2 {
      text-align: left;
      margin: 0;
      font-size: 28px;
      color: #252525;
      font-family: 'Poppins', sans-serif;
      font-weight: 800;
      &:before {
        content: "";
        position: absolute;
        top: 50px;
        left: 15px;
        width: 50px;
        height: 4px;
        background: $orange;
      }
    }
    #first-content-paragraph {
      margin-top: 2.4em;
    }
    p {
      text-align: left;
      color: #252525;
      font-family: 'Poppins', sans-serif;
      font-weight: 300;
      line-height: 1.6em;
      margin-top: 1.6em;
    }
    ul {
      list-style-type: none;
      padding-left: 0;
      li {
        text-align: left;
        color: #252525;
        font-family: 'Poppins', sans-serif;
        font-weight: 600;
        line-height: 27px;
        i {
          font-size: 13px;
          color: #252525;
          font-weight: 600;
          margin-right: 5px;
        }
      }
    }
    .button {
      clear: both;
    }
    a {
      border: none;
      background: $orange;
      color: #ffffff;
      border-radius: 0;
      text-transform: uppercase;
      font-size: 14px;
      font-weight: bold;
      padding: 11px 33px;
      margin-top: 20PX;
      clear: both;
      &:hover {
        color: $orange;
      }
    }
    .picture-wrapper {
      .first {
        overflow: hidden;
        float: right;
      }
    }
  }

</style>