<template>
    <div id="not-found">
        <h3>Error 404: What you're looking for is not here...</h3>
    </div>
    
</template>

<script>
export default {
    name: 'NotFound'
}
</script>

<style lang="scss" scoped>
    #not-found {
        padding: 6em;
        text-align: center;
        width: 100%;
    }
</style>