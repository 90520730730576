<template>
  <section class="offer-wrapper">
    <div class="container">
      <div class="row text-center">
        <img src="/img/rsi-olney.png" alt="Olney, Illinois Warehouse"/>
      </div>
      <div class="row" style="padding: 1em;">
        <div class="item">
          <div class="text-center" style="padding: 2em 0 2em 0">
            <h1>Experience Matters</h1>
          </div>

              At REP Sales, our customers are like family. We know that's corny to say, but with decades of conversations,
              we've come to know their families, their friends, their politics, and their beliefs. We're not just a stranger
              on the other end of the phone. We know you, and we know your business, and we're here for the long haul.


          <br/>
          <hr>
          <br/>


          <div class="row">

            <div class="col-md-4 text-center">
              <div>
                <unicon name="truck" height="32" width="32" fill="brown"></unicon>
              </div>
              <span style="font-size: 2em">
                <strong>FREE Scheduled Delivery</strong>
              </span>
              <br/>
              <br/>
              Call the nearest warehouse to place an order or check the schedule!
              If it fits on our truck, it rides for free. All for the low, low cost
              of a sales visit.
            </div>

            <div class="col-md-4 text-center">
              <div>
                <unicon name="truck" height="32" width="32" fill="brown"></unicon>
              </div>
              <span style="font-size: 2em">
                <strong>FREE Product Research</strong>
              </span>
              <br/>
              <br/>
              Send us a description, a picture, a part number, or a drawing and we'll help source it for you.
            </div>


            <div class="col-md-4 text-center">
              <div>
                <unicon name="truck" height="32" width="32" fill="brown"></unicon>
              </div>
              <span style="font-size: 2em">
                <strong>FREE Estimates & Quotations</strong>
              </span>
              <br/>
              <br/>
              800-274-2003 is all you need to get a quote on any product or service we offer.
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'ExperienceMatters'
}
</script>

<style lang="scss" scoped>
.shipping-icon {
  opacity: 0.3;
}
</style>