<template>
        <section class="quote">
        <h3>
            Your products + Our relationships = Rapid Sales Integration&trade;
        </h3>
    </section>
</template>

<script>
export default {
    name: 'OrangeCalloutRSI'
}
</script>

<style lang="scss" scoped>
  .quote {
    padding: 35px 0;
    background: $orange;
    h3 {
      font-size: 24px;
      line-height: 40px;
      text-align: center;
      font-family: 'Poppins', sans-serif;
      font-weight: 600;
      color: #FFFFFF;
    }
  }
</style>