<template>
  <section class="offer-wrapper" id="learn_more">
    <div class="container">
      <div class="row text-center">
        <h4>
          The 2024-25 Season Is Starting! Submit your Picks Now!
        </h4>
      </div>
      <hr>
      <div class="sign-up-section">
        <unicon name="football-american" height="48" width="48" fill="brown"></unicon>
        <ButtonSecondary :to="pool_url">Sign Up or Sign In</ButtonSecondary>
        <br>
      </div>
      <hr>
      <div class="row">
        <h4>
          You can re-use your existing login or create a new one by using the "Sign Up or Sign In" button
          above. The pre-season will be a practice period. Our pool runs during the regular season.
        </h4>
        <br/>
        <p>
          Our pool has been operating in one form or another for probably 20 years (nobody really
          remembers anymore). The rules have not changed much in the last decade or so:
        </p>
        <ol>
          <li>
            Fees
            <ol>
              <li>
                Entry is $25 and covers the entire season.
              </li>
              <li>
                You can participate every week.
              </li>
              <li>
                Fees are due by September 19, 2024, for players
                who started the season with us. Late joiners can
                pay within 1 week of startup.
              </li>
              <li>
                Late payment? You can be disqualified from all
                current/future weeks, and any winnings passed on
                to the second place finisher for those weeks.
              </li>
            </ol>
          </li>
          <li>
            Submitting Picks
            <ol>
              <li>
                Register or Sign In at <a href="http://www.superbrawl.com/pools.php?wpea1143" target="_blank">Our
                Pool Site</a>.
              </li>
              <li>
                Pick deadline is kickoff of the first game for each week, as scheduled by the NFL.
              </li>
              <li>
                Keep any email confirmations - they shouldn't be needed, but if they are you'll be upset if you
                don't have them.
              </li>
              <li>
                If you miss entry for a week, you get 0 points for that week but can keep entering for other weeks
                if desired.
              </li>
              <li>
                Each week's tie-breaker is the Monday Night game total (both scores combined, last game of the
                week).
              </li>
            </ol>
          </li>
          <li>
            Payout Structure
            <ol>
              <li>This is a zero-profit pool. All fees are paid back to players. We eat the cost of software and
                administration and do this just for fun.
              </li>
              <li>
                50% of total fees collected is put into a <strong>Weekly Prize Pool</strong>, which is then divided
                by the number of regular season weeks.
              </li>
              <li>Each week gets one "share" from this pool, paid out to the top-scoring player (split 50/50 if a
                score tie and tie-breaker tie).
              </li>
              <li>50% of total fees collected is put into a <strong>Season Prize Pool</strong>, which is split 60/40
                between 1st and 2nd place players
                for cumulative season total points.
              </li>
            </ol>
          </li>
        </ol>
      </div>
    </div>
  </section>
</template>

<script>
import ButtonSecondary from "../ux/ButtonSecondary";

export default {
  name: 'FootballPool',
  components: {ButtonSecondary},
  data() {
    return {
      pool_url: 'http://www.superbrawl.com/pools.php?wpea1143'
    }
  }
}
</script>

<style lang="scss" scoped>
  .sign-up-section {
    text-align: center;
    padding: 2em 0 2em 0;
  }
</style>